import { HttpClient } from '@angular/common/http';
import { EventEmitter, Inject, Injectable } from '@angular/core';
import {
	DimBrBrin,
	DimIltOpleidingscode,
	DimKlKlas,
	DimLgLesgroep,
	DimLlLeerling,
	DimMwMedewerker,
	DimNoNormOnderwijsresultaat,
	DimOvOnderwijspositieVergelijkgroep,
	DimPcPostcode,
	DimPerPeriode,
	DimPpvPercentielPrestatieVso,
	DimVkVak,
	DimVsVestiging,
	DimVvVestigingVak,
	FacAwAanwezigheid,
	FacAwAanwezigheidMaand,
	FacAwAanwezigheidSchooljaar,
	FacBvBasisvaardigheden,
	FacCfCijfer,
	FacCkCijferkolommen,
	FacDsDoorstroom,
	FacEkcExamenkandidatenEnCijfers,
	FacLbLoopbaan,
	FacLrLesregistratie,
	FacOrOnderwijsresultaten,
	FacPvPrestatieanalyseVso,
	FacVkkVakkeuze,
	Instelling,
} from '@cumlaude/metadata';
import { parse } from 'json-in-order';
import { difference, flatMap, fromPairs, isArray, isNil, isUndefined, partition, zip } from 'lodash-es';
import { combineLatest, Observable, of, OperatorFunction } from 'rxjs';
import { catchError, map, switchMap, tap } from 'rxjs/operators';
import { DataTree } from './data-tree';
import { FilterName } from './filter-config';
import { Orientation } from './export.service';
import { LegendaEntry, LegendaStyle } from '../legenda/legenda.component';
import { ExportTable } from '../shared/dashboard/data-tree-table/data-tree-table';
import { ENV_CONFIG, EnvConfiguration } from '@cumlaude/shared-configuration';
import { BugsnagService } from '@cumlaude/bugsnag';
import { LeerlingSelectieService } from './leerling-selectie.service';
import { RestLoggingService } from './rest.logging.service';
import { RRol } from '@cumlaude/service-contract';
import { AuthService } from '@cumlaude/shared-authentication';

export type ListOptions = {
	col: AttrPath[];
	f?: FilterExpression;
	sf?: string | string[];
	sp?: string | string[];
	lim?: number;
	off?: number;
};

export interface DataOptions {
	g?: AttrPath[];
	f?: FilterExpression;
	having?: FilterExpression;
	threshold?: number;
	m?: Measure[];
	r?: number[];
	xa?: number[][];
}

export interface ExportFilter {
	label: any;
	value: any;
}

export interface ExportDataOptions {
	f: FilterExpression;
	attrs: Att[];
	attrLabels: string[];
	title?: string;
	filters?: ExportFilter[];
	orientation: Orientation;
	legendaStyle?: LegendaStyle;
	legendaEntries?: LegendaEntry[];
}

export type PlaatsingChild = {
	ds_nm_idu: string;
	ds_is_uitstroom_extern: 0 | 1;
	ds_is_examenprognose: 0 | 1;
	ds_is_prognose: 0 | 1;
	ds_nr_weging: number;
	ds_nr_leerjaar_naar: number;
	ilt_nm_niveau: string;
	ds_nm_vestiging_naar: string;
	ds_nm_schooljaar_naar: string;
	ds_nm_opleiding_naar: string;
	ds_nm_klas_naar: string;
	ilt_nm_profiel: string;
	index?: number;
};

export type Plaatsing = {
	lb_nm_schooljaar: string;
	lb_nm_klas: string;
	lb_nr_leerjaar: number;
	vs_nm_vestiging: string;
	lb_d_plaatsing_va: string;
	lb_d_plaatsing_tm: string;
	ilt_nm_niveau: string;
	ilt_nm_profiel: string;
	lb_nm_opleiding: string;
	lb_nm_opleiding_bekostiging: string;
	lb_is_pl_voorlopig: 0 | 1;
	children: PlaatsingChild[];
};

export type DoorstroomPath = Plaatsing[];

@Injectable({
	providedIn: 'root',
})
export class DataService {
	private errorToBugsnag: OperatorFunction<any, any> = catchError((e) => {
		this.bugsnag.notify(e);
		console.error(e);
		throw e;
	});

	constructor(
		private http: HttpClient,
		@Inject(ENV_CONFIG) private readonly envConfig: EnvConfiguration,
		private bugsnag: BugsnagService,
		private leerlingSelectieService: LeerlingSelectieService,
		private restLoggingService: RestLoggingService,
		protected authService: AuthService
	) {}

	getActiveRolHeader(): {} | { 'X-Restrict-Authorization': string } {
		if (this.restLoggingService.currentDashboard?.startsWith('/beheer/')) {
			return this.getRolHeader(RRol.BEHEERDER);
		}

		const rol = sessionStorage.getItem('rol'); // eigenlijk rolService.getActiveRol() maar circular injection kan niet
		if (isNil(rol)) return {};
		return this.getRolHeader(rol);
	}

	private getRolHeader(rol: string) {
		return { 'X-Restrict-Authorization': rol };
	}

	getLeerlingBasisvaardigheden(nr_leerling: number, vaardigheid: string): Observable<FacBvBasisvaardigheden[]> {
		const options: ListOptions = {
			f: new CompoundFilterExpression([
				new BasicFilterExpression(['bv_nr_leerling'], nr_leerling),
				new BasicFilterExpression(['bv_nm_vaardigheid'], vaardigheid),
			]),
			col: [
				['bv_d_afname'],
				['bv_pk_key'], // zodat ze gesorteerd worden op bv_fun_sortkey_afname: (bv_d_afname,bv_pk_key)
				['bv_nm_leerfase'],
				['bv_nr_referentieniveau'],
				['bv_nm_referentieniveau'],
				['bv_nm_toetsmoment'],
				['bv_nm_schooljaar'],
			],
		};
		return this.getList<FacBvBasisvaardigheden>('basisvaardigheden-details/list', options).pipe(map((table) => table.rows));
	}

	getLeerlingen<T>(options: Partial<ListOptions>): Observable<TableResponse<T>> {
		return this.getList<T>('leerling/list', options);
	}

	getMedewerkers<T>(options: Partial<ListOptions>): Observable<TableResponse<T>> {
		return this.getList<T>('medewerker/list', options);
	}

	getBeheerMedewerkersLijst<T>(): Observable<TableResponse<T>> {
		return this.getList('medewerker/beheerlist', {});
	}

	getVestigingen(options?: Partial<ListOptions>): Observable<DimVsVestiging[]> {
		return this.getList<TableResponse<DimVsVestiging>>('vestiging/list', {
			...options,
			col: [
				['vs_id_vestiging'],
				['vs_is_actief'],
				['vs_is_vo'],
				['vs_is_vso'],
				['vs_nm_vestiging'],
				['vs_cos_brinvest'],
				['vs_cos_brinvest_actief'],
			],
		}).pipe(
			this.errorToBugsnag,
			map((table) => table.rows)
		);
	}

	getKlassen(): Observable<DimKlKlas[]> {
		const col: AttrPath[] = [['kl_nm_klas'], ['kl_id_vestiging']];
		return this.getList<DimKlKlas>('klas/list', { col }).pipe(map((table) => table.rows));
	}

	getVestigingVakken(): Observable<DimVvVestigingVak[]> {
		const col: AttrPath[] = [['vv_nm_vak'], ['vv_abb_vak'], ['vv_id_vestiging']];
		return this.getList<DimVvVestigingVak>('vestiging_vak/list', { col }).pipe(map((table) => table.rows));
	}

	getList<T>(endpoint: string, options: Partial<ListOptions>): Observable<TableResponse<T>> {
		const url = `${this.envConfig.dataUrl}/${endpoint}`;
		const { col, f, sf, sp, lim, off } = options;

		return this.authService.refreshTokenIfNeeded().pipe(
			switchMap(() => this.getFilterOptions({ f, sf, sp })),
			switchMap((filterOptions) => {
				const params = {
					...(col ? { col: col.map((p) => p.join('.')) } : {}),
					...filterOptions,
					...(lim ? { lim } : {}),
					...(off ? { off } : {}),
				};
				return this.http
					.get(url, {
						params,
						headers: this.getActiveRolHeader(),
						observe: 'body',
						responseType: 'text',
					})
					.pipe(this.errorToBugsnag, map(parseTableJson), tap(logResponseQueries<TableResponse<T>>));
			})
		);
	}

	getLeerlingDetails<T>(col: Attr[][], key?: number): Observable<TableResponse<T>> {
		return this.getDetails<T>('loopbaan/leerlingdetails', col, key);
	}

	getMedewerkerDetails<T>(col: Attr[][], key?: number): Observable<TableResponse<T>> {
		return this.getDetails<T>('medewerker/details', col, key);
	}

	getDetails<T>(endpoint: string, col: Attr[][], key?: number): Observable<TableResponse<T>> {
		const url = `${this.envConfig.dataUrl}/${endpoint}`;
		const params = {
			col: col.map((p) => p.join('.')),
			key: JSON.stringify(key),
		};
		return this.authService.refreshTokenIfNeeded().pipe(
			switchMap(() =>
				this.http.get(url, {
					params,
					headers: this.getActiveRolHeader(),
					observe: 'body',
					responseType: 'text',
				})
			),
			this.errorToBugsnag,
			map(parseTableJson),
			tap(logResponseQueries<TableResponse<T>>)
		);
	}

	getDoorstroomDetails(nr_leerling: number): Observable<DoorstroomPath[]> {
		const url = `${this.envConfig.dataUrl}/doorstroom/details/${nr_leerling}`;
		return this.http.get<DoorstroomPath[]>(url, { headers: this.getActiveRolHeader() }).pipe(this.errorToBugsnag);
	}

	getData(endpoint: string, options: DataOptions): Observable<DataResponse<number[]>> {
		return this.authService.refreshTokenIfNeeded().pipe(
			switchMap(() => this.getFilterOptions({ f: options.f })),
			switchMap((filterOptions) => {
				const params = {
					...(options.g ? { g: options.g.map((p) => p.join('.')) } : {}),
					...(options.r ? { r: options.r } : {}),
					...(options.xa ? { xa: options.xa.map((v) => JSON.stringify(v)) } : {}),
					...filterOptions,
					...(options.having ? { having: JSON.stringify(options.having) } : {}),
					...(options.threshold ? { threshold: options.threshold } : {}),
					...(options.m ? { m: options.m } : {}),
				};
				const url = `${this.envConfig.dataUrl}/${endpoint}`;
				return this.http.get(url, {
					headers: { Accept: 'text/json', ...this.getActiveRolHeader() },
					params,
					observe: 'body',
					responseType: 'text',
				});
			}),
			this.errorToBugsnag,
			map(parseTreeJson),
			tap(logResponseQueries<DataResponse<number[]>>)
		);
	}

	/**
	 * Converteert de verschillende filter-opties (f, of, sf, sp) naar strings die als parameters naar de backend kunnen.
	 *
	 * De backend kan niet omgaan met SelectionFilterExpressions binnen een FilterExpression; die halen we eruit en leveren we als aparte sp+sf
	 * parameters aan. Dit geldt ook voor een evt. SelectionFilterExpression die binnen inOptions.sf zit (dit komt voor als er doorgeklikt wordt naar
	 * een detail-dashboard terwijl er al een leerlingselectie actief is). Ook deze wordt er dus uit gehaald; ipv dat dit een filter wordt op de
	 * subquery-dataprovider, wordt het een filter op de hoofd-dataprovider.
	 */
	private getFilterOptions(inOptions: {
		f?: FilterExpression;
		of?: FilterExpression[];
		sf?: string | string[];
		sp?: string | string[];
	}): Observable<{ f?: string; sp: string[]; sf: string[]; of: string[] }> {
		const sp = makeArray(inOptions.sp);
		const sfsIn: FilterExpression[] = makeArray(inOptions.sf).map((f) => JSON.parse(f));
		if (sp.length !== sfsIn.length) {
			throw new Error(`lengths of sp/sf don't match (${sp.length}/${sfsIn.length})`);
		}

		const [selectionFilters0, f] = inOptions.f ? extractSelectionFilters(inOptions.f) : [[], undefined];
		const [selectionFilters1, ofs] = extractSelectionFilters(inOptions.of ?? []);
		const [selectionFilterss, sfs] = zip(...sfsIn.map((f) => extractSelectionFilters(f))) as [SelectionFilterExpression[][], FilterExpression[]];
		const selectionFilters = [...selectionFilters0, ...selectionFilters1, ...flatMap(selectionFilterss ?? [])];

		// NB combineLatest([]) blijft altijd hangen!
		return (
			selectionFilters.length === 0
				? of([])
				: combineLatest(selectionFilters.map(({ selectionId }) => this.leerlingSelectieService.get(selectionId)))
		).pipe(
			map((sels) => {
				return {
					...(f ? { f: JSON.stringify(f) } : {}),
					of: ofs.map((f) => JSON.stringify(f)),
					sp: [...sp, ...sels.map((x) => x.selectionProvider)],
					sf: [...(sfs ?? []).map((sf) => JSON.stringify(sf)), ...sels.map((x) => x.selectionFilter)],
				};
			})
		);
	}

	getExportData(endpoint: string, options: ExportDataOptions): Observable<Blob> {
		return this.authService.refreshTokenIfNeeded().pipe(
			switchMap(() => this.getFilterOptions({ f: options.f })),
			switchMap((filterOptions) => {
				const params = {
					...filterOptions,
					attrs: options.attrs,
					attrLabels: options.attrLabels,
					...(options.title ? { title: options.title } : {}),
					...(options.filters ? { filters: JSON.stringify({ filters: options.filters }) } : {}),
				};
				const url = `${this.envConfig.dataUrl}/${endpoint}/exportdata`;
				return this.http.get(url, {
					headers: { Accept: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet', ...this.getActiveRolHeader() },
					params,
					observe: 'body',
					responseType: 'blob',
				});
			}),
			this.errorToBugsnag
		);
	}

	getTableExport(data: ExportTable<any>): Observable<Blob> {
		const url = `${this.envConfig.dataUrl}/tableexport`;
		return this.authService.refreshTokenIfNeeded().pipe(
			switchMap(() =>
				this.http.post(url, data, {
					headers: {
						Accept: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
						'Content-Type': 'application/json',
						...this.getActiveRolHeader(),
					},
					observe: 'body',
					responseType: 'blob',
				})
			),
			this.errorToBugsnag
		);
	}

	/**
	 * Gaat ervan uit dat options.f een CompoundFilterExpression is.
	 * Geeft een nieuwe {f,having} terug waarbij eventuele filters (een BasicFilterExpression) op alle meegegeven attrs uit de f
	 * zijn verplaatst naar having.
	 */
	moveToHaving(attrs: AttrPath[], options: DataOptions): { f?: FilterExpression; having?: FilterExpression } {
		const compound = options.f!;

		const [having, f] = this.partitionByFilterAttr(compound, attrs)
			.map((filters) => filters.filter((f) => !f.isEmpty()))
			.map((filters) => (filters.length ? new CompoundFilterExpression(filters) : undefined));
		return { f, having };
	}

	partitionByFilterAttr(compound: FilterExpression, attrs: AttrPath[]): [FilterExpression[], FilterExpression[]] {
		if (!(compound instanceof CompoundFilterExpression))
			throw new Error(`Expected: CompoundFilterExpression. Actual: ${JSON.stringify(compound)}`);
		const atts = attrs.map((attr) => attr.join('.'));
		return partition(compound.filters, (filter) => atts.includes(<Att>asBasicFilterExpression(filter)?.attr.join('.')));
	}

	getFilterVal(compound: FilterExpression, attr: AttrPath): string | undefined {
		const [target] = this.partitionByFilterAttr(compound, [attr]);
		return target[0]?.getValueString();
	}

	getAanwezigheidData(options: DataOptions): Observable<DataResponse<number[]>> {
		return this.getData('aanwezigheid', options);
	}

	getAanwezigheidExportData(options: ExportDataOptions): Observable<Blob> {
		return this.getExportData('aanwezigheid', options);
	}

	getBasisvaardighedenData(options: DataOptions): Observable<DataResponse<number[]>> {
		return this.getData('basisvaardigheden', options);
	}

	getBasisvaardighedenExportData(options: ExportDataOptions): Observable<Blob> {
		return this.getExportData('basisvaardigheden', options);
	}

	getCijferkolommenData(options: DataOptions): Observable<DataResponse<number[]>> {
		return this.getData('cijferkolommen', options);
	}

	getCijfersData(options: DataOptions): Observable<DataResponse<number[]>> {
		return this.getData('cijfers', options);
	}

	getCijfersExportData(options: ExportDataOptions): Observable<Blob> {
		return this.getExportData('cijfers', options);
	}

	getDoorstroomData(options: DataOptions): Observable<DataResponse<number[]>> {
		return this.getData('doorstroom', options);
	}

	getDoorstroomCohortdetailsGraphData(options: { f?: FilterExpression }): Observable<DataResponse<number[]>> {
		return this.getData('cohortdetails/graph', options);
	}

	getDoorstroomCohortdetailsExportData(options: ExportDataOptions): Observable<Blob> {
		return this.getExportData('cohortdetails', options);
	}

	getDoorstroomExportData(options: ExportDataOptions): Observable<Blob> {
		return this.getExportData('doorstroom', options);
	}

	getExamencijfersData(options: DataOptions): Observable<DataResponse<number[]>> {
		return this.getData('examencijfers', options);
	}

	getExamencijfersExportData(options: ExportDataOptions): Observable<Blob> {
		return this.getExportData('examencijfers', options);
	}

	getLeerfaseData(): Observable<DataResponse<number[]>> {
		return this.getData('loopbaan/leerfases', {});
	}

	getLesregistratieData(options: DataOptions): Observable<DataResponse<number[]>> {
		return this.getData('lesregistratie', options);
	}

	getLesregistratieExportData(options: ExportDataOptions): Observable<Blob> {
		return this.getExportData('lesregistratie', options);
	}

	getLoopbaanData(options: DataOptions): Observable<DataResponse<number[]>> {
		return this.getData('loopbaan', options);
	}

	getLoopbaanExportData(options: ExportDataOptions): Observable<Blob> {
		return this.getExportData('loopbaan', options);
	}

	getOnderwijsresultatenData(options: DataOptions): Observable<DataResponse<number[]>> {
		return this.getData('onderwijsresultaten', options);
	}

	getOnderwijsresultatenExportData(options: ExportDataOptions): Observable<Blob> {
		return this.getExportData('onderwijsresultaten', options);
	}

	getPrestatieanalyseVsoData(options: DataOptions): Observable<DataResponse<number[]>> {
		return this.getData('prestatieanalyse_vso', options);
	}

	getVakkeuzeData(options: DataOptions): Observable<DataResponse<number[]>> {
		return this.getData('vakkeuze', options);
	}

	getVakkeuzeExportData(options: ExportDataOptions): Observable<Blob> {
		return this.getExportData('vakkeuze', options);
	}

	getSql(endpoint: string | string[], options: { f?: FilterExpression | FilterExpression[] }): Observable<string> {
		const url = `${this.envConfig.dataUrl}/${makeArray(endpoint)[0]}`;
		const params = {
			...(options.f ? { f: JSON.stringify(options.f) } : {}),
		};
		return this.authService.refreshTokenIfNeeded().pipe(
			switchMap(() =>
				this.http.get(url, {
					headers: { Accept: 'application/sql', ...this.getActiveRolHeader() },
					params,
					observe: 'body',
					responseType: 'text',
				})
			),
			this.errorToBugsnag
		);
	}

	fetchFirstFilterValue<T>(endpoint: string, attr: AttrPath, otherFilters: FilterExpression[] = []): Observable<T | undefined> {
		return this.getActiveFilterValues(endpoint, new QueryFilterExpression(attr), otherFilters, []).pipe(
			map((resp) => resp.values[0]),
			catchError((_err) => {
				return of(undefined);
			})
		);
	}

	fetchFirstFilterValueAsArray<T>(endpoint: string, attr: AttrPath, otherFilters: FilterExpression[] = []): Observable<T[] | undefined> {
		return this.fetchFirstFilterValue<T>(endpoint, attr, otherFilters).pipe(
			map((value: T | undefined) => (!isUndefined(value) ? [value] : undefined))
		);
	}

	getFilterValues(
		endpoint: string,
		filter: FilterExpression,
		otherFilters: FilterExpression[],
		permanentFilters: FilterExpression[]
	): Observable<FilterData> {
		const activeFilterValues$ = this.getActiveFilterValues(endpoint, filter, otherFilters, permanentFilters);
		const allFilterValues$ = this.getAllFilterValues(endpoint, filter, permanentFilters);
		return combineLatest([activeFilterValues$, allFilterValues$]).pipe(
			map(([activeFV, allFV]) => ({
				attr: activeFV.attr,
				allValues: allFV.values,
				activeValues: activeFV.values,
				inactiveValues: difference(allFV.values, activeFV.values),
			}))
		);
	}

	getActiveFilterValues(
		endpoint: string,
		filter: FilterExpression,
		otherFilters: FilterExpression[],
		permanentFilters: FilterExpression[]
	): Observable<FilterValues> {
		const url = `${this.envConfig.dataUrl}${endpoint}/activefiltervalues`;

		return this.authService.refreshTokenIfNeeded().pipe(
			switchMap(() => this.getFilterOptions({ f: filter, of: otherFilters })),
			switchMap((filterOptions) => {
				const params = {
					...filterOptions,
					pf: JSON.stringify(new CompoundFilterExpression(permanentFilters)),
				};
				return this.http.get<FilterValues>(url, { params, headers: this.getActiveRolHeader() });
			}),
			this.errorToBugsnag
		);
	}

	getAllFilterValues(endpoint: string, filter: FilterExpression, permanentFilters: FilterExpression[]): Observable<FilterValues> {
		const url = `${this.envConfig.dataUrl}${endpoint}/allfiltervalues`;
		const params = {
			f: JSON.stringify(filter),
			pf: JSON.stringify(new CompoundFilterExpression(permanentFilters)),
		};
		return this.authService.refreshTokenIfNeeded().pipe(
			switchMap(() => this.http.get<FilterValues>(url, { params, headers: this.getActiveRolHeader() })),
			this.errorToBugsnag
		);
	}
}

export type Attr = keyof DWHTable | 'x_values';

export type AttrPath = Attr[];

export type DWHTable = FacAwAanwezigheid &
	FacAwAanwezigheidMaand &
	FacAwAanwezigheidSchooljaar &
	FacBvBasisvaardigheden &
	FacCfCijfer &
	FacCkCijferkolommen &
	FacDsDoorstroom &
	FacEkcExamenkandidatenEnCijfers &
	FacLrLesregistratie &
	FacLbLoopbaan &
	FacOrOnderwijsresultaten &
	FacPvPrestatieanalyseVso &
	FacVkkVakkeuze &
	DimBrBrin &
	DimIltOpleidingscode &
	DimKlKlas &
	DimLgLesgroep &
	DimLlLeerling &
	DimMwMedewerker &
	DimNoNormOnderwijsresultaat &
	DimOvOnderwijspositieVergelijkgroep &
	DimPcPostcode &
	DimPerPeriode &
	DimPpvPercentielPrestatieVso &
	DimVkVak &
	DimVsVestiging &
	DimVvVestigingVak &
	Instelling;

type Keys<T> = string & keyof T;
type AttrStringOf<T> = { [A in Keys<T>]: `${A}` | `${A}.${Keys<NonNullable<T[A]>>}` };
type ValuesOf<T> = NonNullable<T[keyof T]>;
export type Att = ValuesOf<AttrStringOf<DWHTable>> | `cf_fks_mw.${Keys<DimMwMedewerker>}` | `lr_fks_mw.${Keys<DimMwMedewerker>}`;

export function NO_DATA(options: { g: AttrPath[] }): Observable<DataResponse<number[]>> {
	return of({ g: options.g.map((p) => p.join('.')), measures: [], data: [], metadata: undefined, queries: [] });
}

export interface DataResponse<D> extends QueryResponse {
	g: string[];
	measures: string[];
	data: DataTree<D>;
	order?: DataTree<[]>;
	metadata?: Map<string, string>;
}

export interface TableResponse<R> extends QueryResponse {
	columns: string[];
	rows: R[];
	total: number;
}

export interface QueryResponse {
	queries: string[];
}

export interface FilterData {
	attr: string[];
	allValues: any[];
	activeValues: any[];
	inactiveValues: any[];
}

export interface FilterValues {
	attr: string[];
	values: any[];
}

export interface FilterExpression {
	getValueString(): string;

	isEmpty(): boolean;
}

export class CompoundFilterExpression implements FilterExpression {
	constructor(
		public filters: FilterExpression[],
		public type: 'and' | 'or' = 'and'
	) {}

	isEmpty = () => this.type === 'and' && this.filters.length === 0;

	getValueString(): string {
		return this.filters.map((f) => f.getValueString()).join(` ${this.type} `);
	}
}

export type BasicFilterExpressionType = '=' | '>' | '<' | '<=' | '>=' | '<>' | 'in' | 'not in' | 'any' | 'like' | 'like_unaccent';

export function isQueryFilterExpression(fex: FilterExpression | QueryFilterExpression): fex is QueryFilterExpression {
	if (!('type' in fex)) return false;

	return fex.type === '?';
}

export class QueryFilterExpression implements FilterExpression {
	type = '?';

	constructor(public attr: AttrPath) {}

	isEmpty = () => true;

	getValueString(): string {
		return '';
	}
}

export class BasicFilterExpression<T> implements FilterExpression {
	constructor(
		public attr: AttrPath,
		public val: T | null = null,
		public type: BasicFilterExpressionType = '=',
		public otherAttr: AttrPath | undefined = undefined
	) {}

	isEmpty = () => isNil(this.val);

	getValueString(): string {
		return this.val ? `${this.val}` : '';
	}
}

export class BetweenFilterExpression<T> implements FilterExpression {
	constructor(
		public attr: AttrPath,
		public from: T | null,
		public to: T | null
	) {}

	type = 'between';

	isEmpty = () => isNil(this.from) || isNil(this.to);

	getValueString(): string {
		return !this.isEmpty() ? `between ${this.from} and ${this.to}` : '';
	}
}

export class SelectionFilterExpression implements FilterExpression {
	constructor(public selectionId: string) {}

	isEmpty = () => false;

	getValueString(): string {
		return `SelectionId:${this.selectionId}`;
	}
}

export class InFilterExpression<T extends Array<any>> extends BasicFilterExpression<T> {
	constructor(
		public attr: AttrPath,
		public val: T | null = null
	) {
		super(attr, val, 'in');
	}

	isEmpty = () => isNil(this.val) || this.val.length === 0;

	getValueString(): string {
		return this.val ? this.val.map((v) => v.toString()).join(', ') : '';
	}
}

export function extractSelectionFilters(filterOrFilters: FilterExpression): [SelectionFilterExpression[], FilterExpression];
export function extractSelectionFilters(filterOrFilters: FilterExpression[]): [SelectionFilterExpression[], FilterExpression[]];
export function extractSelectionFilters(
	filterOrFilters: FilterExpression | FilterExpression[]
): [SelectionFilterExpression[], FilterExpression | FilterExpression[]] {
	const filters = isArray(filterOrFilters) ? filterOrFilters : flattenAnd(filterOrFilters);
	const [selectionFilters, normalFilters] = partition(filters, isSelectionFilterExpression);
	if (isArray(filterOrFilters)) return [selectionFilters, normalFilters];
	if (normalFilters.length == 1) return [selectionFilters, normalFilters[0]];

	return [selectionFilters, new CompoundFilterExpression(normalFilters)];
}

function isBasicFilterEpxression(filter: FilterExpression): filter is BasicFilterExpression<any> {
	return filter instanceof BasicFilterExpression;
}

function isInFilterExpression(filter: FilterExpression): filter is InFilterExpression<any> {
	return filter instanceof InFilterExpression;
}

function asBasicFilterExpression(filter: FilterExpression): BasicFilterExpression<any> | undefined {
	return isBasicFilterEpxression(filter) ? filter : undefined;
}

export function flattenFilters(filter: FilterExpression | FilterExpression[]): (BasicFilterExpression<any> | InFilterExpression<any>)[] {
	if (isArray(filter)) return flatMap(filter.map((sub) => flattenFilters(sub)));
	else if (isCompoundFilterExpression(filter)) return flattenFilters(filter.filters);
	else if (isBasicFilterEpxression(filter) || isInFilterExpression(filter)) return [filter];
	return [];
}

function isSelectionFilterExpression(filter: FilterExpression): filter is SelectionFilterExpression {
	return 'selectionId' in filter;
}

function isCompoundFilterExpression(filter: FilterExpression): filter is CompoundFilterExpression {
	return 'type' in filter && ['and', 'or'].includes(filter.type as string);
}

function flattenAnd(filter: FilterExpression): FilterExpression[] {
	if (isCompoundFilterExpression(filter) && filter.type === 'and') return flatMap(filter.filters, flattenAnd);
	return [filter];
}

function makeArray<T>(opt?: T | T[]): T[] {
	if (opt === undefined) return [];
	if (isArray(opt)) return opt;
	return [opt];
}

export interface FilterComponent<T> {
	filterName: FilterName;
	inDropdown: boolean;
	searchInput: string | undefined;
	searchInputChange$?: EventEmitter<string>;
}

function parseTableJson(input: string): TableResponse<any> {
	const res = parse(input) as Map<string, any>;
	const columns = res.get('columns');
	const data = <any[]>res.get('data');
	const rows = data.map((row) => fromPairs(zip(columns, row)));
	const total = res.get('total');
	const queries = res.get('queries');
	return { columns, rows, total, queries };
}

function logResponseQueries<T extends QueryResponse>(response: T): void {
	if (!response.queries) return;

	response.queries.forEach((query) => console.log(query));
}

function parseTreeJson(input: string): DataResponse<number[]> {
	const res = parse(input) as Map<string, any>;
	return {
		g: res.get('g'),
		measures: res.get('measures'),
		data: res.get('data'),
		order: res.get('order'),
		metadata: res.get('metadata'),
		queries: res.get('queries'),
	};
}

export enum CijferMeasure {
	HISTOGRAM = 'histogram',
	RELATIE = 'relatie',
	PLAATSING_ADVIES = 'plaatsing_advies',
	STATS = 'stats',
	SE_CE = 'se_ce',
	SE = 'se',
	CE = 'ce',
	LABEL = 'label',
	TOETS = 'toets',
	BIJZONDERHEID = 'bijzonderheid',
	AANWEZIGHEID = 'aanwezigheid',
}

export enum AanwezigheidMeasure {
	LESUREN = 'lesuren',
	LESDAGEN = 'lesdagen',
	LEERLINGEN = 'leerlingen',
}

export enum DoorstroomMeasure {
	VERSCHILPUNT = 'verschilpunt',
	LEERLINGEN = 'leerlingen',
}

type Measure = CijferMeasure | DoorstroomMeasure | AanwezigheidMeasure;

export const defaultDoorstroomAvailableGroups: AttrPath[] = [
	['ds_fk_ll', 'll_nm_basisschooladvies_uni'],
	['ds_fk_ll', 'll_nm_basisschooladvies_uni_herzien'],
	['ds_fk_ll', 'll_nm_basisschooladvies_duo'],
	['ds_nm_bekostigingstype_van'],
	['ds_nm_uitstroomprofiel_vso_van'],
	['ds_co_brin_svh'],
	['ds_fk_ll', 'll_nm_geslacht'],
	['ds_fk_ll', 'll_nm_nationaliteit'],
	['ds_fk_ilt_van', 'ilt_nm_niveau'],
	['ds_fk_ll', 'll_nm_onderwijssoort_svh'],
	['ds_nm_klas_van'],
	['ds_nr_leerjaar_van'],
	['ds_nm_opleiding_van'],
	['ds_fk_ilt_van', 'ilt_nm_opleiding'],
	['ds_fk_pc_leerling_van', 'pc_nr_pc4'],
	['ds_fk_ilt_van', 'ilt_abb_profiel'],
	['ds_fk_ll', 'll_nm_svh'],
	['ds_fk_vs_van', 'vs_nm_vestiging'],
	['ds_fk_pc_leerling_van', 'pc_nm_plaats'],
];

export const defaultDoorstroomSelectedGroups: AttrPath[] = [['ds_fk_ilt_van', 'ilt_nm_niveau'], ['ds_nr_leerjaar_van']];

/**
 * Produceert een lijstje voor de `xa` parameter dat staat voor een aggregatie over alle `groups`
 * behalve `target` (ofwel een GROUP BY over alleen `target`).
 */
export function xAggExcept(groups: AttrPath[], target: AttrPath): number[] {
	return groups.flatMap((group, ix) => (group.join('.') === target.join('.') ? [] : [ix]));
}
