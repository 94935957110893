import { Component, computed, effect, OnInit, Signal } from '@angular/core';
import {
	AttrPath,
	BasicFilterExpression,
	CijferMeasure,
	CompoundFilterExpression,
	DataOptions,
	DataResponse,
	DataService,
	ExportDataOptions,
	ExportFilter,
	FilterExpression,
	InFilterExpression,
} from '../../services/data.service';
import { FilterName } from '../../services/filter-config';
import { Observable } from 'rxjs';
import { FilterService } from '../../services/filter.service';
import { QueryParamStateService } from '../../services/query-param-state.service';
import { noAgg, SingleAggregator, sumOver } from '../../services/aggregation';
import { Cijferkolomtype } from '@cumlaude/metadata';
import { ColumnDef, createColumnDef, TableModel } from '../../shared/components/table/table/table.model';
import { att, percOfRow } from '../../services/measures';
import {
	createMeasureColumn,
	DataRow,
	DataTreeTableComponent,
	DUMMY_ROW_ID,
	getInitialAttributes,
	getSubgroupAttributes,
} from '../../shared/dashboard/data-tree-table/data-tree-table';
import { getLeafA, Level, Path } from '../../services/data-tree';
import { intersection, last, memoize, partition, range, sortBy, sum, union } from 'lodash-es';
import { PivotSeriesHeaderCellComponent } from '../../shared/components/table/cells/pivot-series-header-cell/pivot-series-header-cell.component';
import { ToetsData, ToetsPeriodeCellComponent } from '../../shared/components/table/cells/toets-periode-cell/toets-periode-cell.component';
import { Option, FormDropdownComponent } from '@cumlaude/shared-components-inputs';
import { deelVeilig } from '@cumlaude/shared-utils';
import { PivotTableConfig } from '../../shared/dashboard/pivot-table/pivot-table-config';
import { Attributes, LinkData } from '../../shared/dashboard/base-dashboard/base-dashboard-config';
import { DashboardContext } from '../../shared/dashboard/base-dashboard/dashboard-context';
import { FactTable } from '../../services/exportable';
import { ToastrService } from 'ngx-toastr';
import { CijferkolomKeuze, Dossier } from '../../services/weergave-opties';
import { InstellingBron } from '@cumlaude/service-contract';
import { PivotTableComponent } from '../../shared/dashboard/pivot-table/pivot-table.component';
import { DashboardHeaderComponent } from '../../dashboard-header/dashboard-header.component';
import { FilterPanelComponent } from '../../filter-panel/filter-panel.component';
import { DashboardContainerComponent } from '../../layout/dashboard-container/dashboard-container.component';

export interface ToetsI extends Attributes {
	cf_fun_kolomgroep: any;
	cf_co_kolom_agg: any;
	clc_abb_label_avg: any;
	clc_is_voldoende_avg: number;
	cf_nm_bijzonderheid: any;
	cf_abb_kolomkop_agg: any;
	cf_des_kolom_agg: any;
	cf_nr_leerlingen: number;
	cf_nr_cijfer: number;
	cf_nr_decimalen: number;
	cf_is_gevuld: number;
	cf_is_voldoende: number;
	cf_nr_weging_agg: any;
	cf_nr_tekortpunten: number;
	cf_nm_kolomtype: any;
}

export interface ToetsA extends Attributes {
	cf_nr_leerlingen: number | null;
	gem_cijfer: number | null;
	sum_cijfer: number | null;
	sum_tekortpunten: number | null;
	cf_is_gevuld: number;
	onvoldoendes: number;
}

@Component({
	selector: 'app-toets',
	templateUrl: './toets.component.html',
	styleUrls: ['./toets.component.scss'],
	providers: [{ provide: DataTreeTableComponent, useExisting: ToetsComponent }], // zorgt dat dit component als subclass van DataTreeTableComponent wordt herkend voor het ViewChild-attribuut van DataTreeTableConfigConfig
	standalone: true,
	imports: [DashboardContainerComponent, FilterPanelComponent, DashboardHeaderComponent, FormDropdownComponent, PivotTableComponent],
})
export class ToetsComponent extends PivotTableConfig<ToetsI, ToetsA> implements OnInit {
	InstellingBron = InstellingBron;

	fixedBeforeGroups = 2;

	/**
	 * Het dashboard bestaat uit meerdere tables, 1 voor elke leerfase (= niveau + leerjaar).
	 * Dit omdat de kolommen nogal kunnen verschillen tussen leerfases.
	 */
	tableGroups: AttrPath[] = [['cf_fk_lb', 'lb_nm_leerfase']];

	/**
	 * De eerste groepering is altijd cf_nm_vak.
	 * Verdere groeperingen zijn door de gebruiker te kiezen en komen in dit veld.
	 */
	groups: AttrPath[] = [];

	/**
	 * Subgroups staan vast en zorgen ervoor dat een cel overeenkomt met een bepaalde toets, nl:
	 * 1. van de gekozen groepering
	 * 2. en de periode uit de bovenste kop
	 * 3. en de "cf_fun_kolomgroep" (= ofwel cf_nr_kolom, ofwel cf_abb_kolomkop, afhankelijk van kolomtype)
	 */
	subgroups: AttrPath[] = [['cf_fun_periode'], ['cf_nm_kolomtype'], ['cf_fun_kolomgroep']];

	availableGroups: AttrPath[] = [
		['cf_fk_ll', 'll_nm_basisschooladvies_uni'],
		['cf_fk_ll', 'll_nm_basisschooladvies_uni_herzien'],
		['cf_fk_ll', 'll_nm_basisschooladvies_duo'],
		['cf_fk_lb', 'lb_nm_uitstroomprofiel_vso'],
		['cf_fks_mw', 'mw_nm_medewerker'],
		['cf_nm_opleiding'],
		['cf_nm_klas'],
		['cf_nm_leerling'],
		['cf_nm_lesgroep'],
		['cf_nm_lesgroep_docenten'],
		['cf_nr_leerjaar'],
		['cf_fk_ilt', 'ilt_nm_niveau'],
		['cf_fk_ilt_vorig_sj', 'ilt_nm_niveau'],
		['cf_fk_ilt', 'ilt_abb_profiel'],
		['cf_fun_nm_vak_uni'],
		['cf_fk_vk', 'vk_nm_vak'],
		['cf_abb_onderwijssoort_vak'],
		['cf_nm_vestiging'],
		['cf_fk_lb_vorig_sj', 'lb_nm_vestiging'],
		['cf_fk_lb_vorig_sj', 'lb_nm_leerfase'],
	];

	toetsFilters: FilterName[] = [
		'cf_nm_schooljaar',
		'cf_fk_lb.lb_co_brin',
		'cf_nm_vestiging',
		'cf_fk_ilt.ilt_nm_niveau',
		'cf_nr_leerjaar',
		'x_cf_is_alternatievenormering',
		'cf_nm_vak',
		'cf_nm_lesgroep',
		'cf_fun_periode',
		'cf_fk_lb.lb_nm_leerfase',
		'cf_fks_mw.mw_nm_medewerker',
	];

	filterExpressions?: FilterExpression[];

	dossier!: Dossier;

	dossierOpties = Object.values(Dossier).map((val) => new Option(val));

	bron!: InstellingBron;

	cijferkolomKeuze!: CijferkolomKeuze;

	cijferkolomKeuzeOpties: Signal<Option<CijferkolomKeuze>[]>;

	constructor(
		protected dataService: DataService,
		protected filterService: FilterService,
		public qp: QueryParamStateService,
		protected toastr: ToastrService
	) {
		super(filterService, toastr);
		const groupsSignal = this.qp.signal_g();
		const cijferkolomKeuzes = computed(() => {
			const groups = groupsSignal();
			const options = [CijferkolomKeuze.ALLE, CijferkolomKeuze.TOETS];

			if (groups?.map((group) => group.join('.')).includes('cf_nm_leerling')) options.push(CijferkolomKeuze.ADVIES);

			options.push(CijferkolomKeuze.GEMIDDELDE);

			return options;
		});
		this.cijferkolomKeuzeOpties = computed(() => cijferkolomKeuzes().map((val) => new Option(val)));
		effect(() => {
			const keuzes = cijferkolomKeuzes();
			if (this.cijferkolomKeuze !== CijferkolomKeuze.ADVIES || keuzes.includes(CijferkolomKeuze.ADVIES)) return;

			this.qp.dispatch('cijfertype', CijferkolomKeuze.TOETS);
		});
	}

	createPivotColumns(columnRoot: Level<ToetsA, number[]>, context: DashboardContext<ToetsI, ToetsA, ToetsComponent>): ColumnDef<DataRow<ToetsA>>[] {
		return columnRoot.c.map((periodeLvl) => this.createPeriodeColumn(periodeLvl, context));
	}

	protected createPeriodeColumn(
		lvl: Level<ToetsA, number[]>,
		context: DashboardContext<ToetsI, ToetsA, ToetsComponent>
	): ColumnDef<DataRow<ToetsA>> {
		const { subgroupNames } = context;
		const periode = lvl.k!;
		const toetsen = lvl.r.map((path) => getSubgroupAttributes<ToetsI>(subgroupNames, path).cf_fun_kolomgroep);
		const colKey = `periode-${periode}-${toetsen.join('-')}`;
		const coldef = createColumnDef<DataRow<ToetsA>>(colKey);
		coldef.sortable = this.cijferkolomKeuze === CijferkolomKeuze.GEMIDDELDE;
		coldef.header.component = PivotSeriesHeaderCellComponent;
		coldef.header.class = 'toets-cell';
		coldef.header.getValue = () => ({ seriesKey: periode, colKeys: toetsen });
		const start = last(lvl.r[0])!.i;
		const end = last(last(lvl.r))!.i + 1;
		coldef.body.component = ToetsPeriodeCellComponent;
		coldef.body.class = 'toets-cell';
		coldef.body.getValue = this.makePeriodeData(periode, start, end, context);
		return coldef;
	}

	private makePeriodeData(
		periode: string,
		start: number,
		end: number,
		context: DashboardContext<ToetsI, ToetsA, ToetsComponent>
	): (row: DataRow<ToetsA>) => (ToetsData | null)[] | number {
		const { groupNames, subgroupNames, measureNames } = context;
		return (row: DataRow<ToetsA>) => {
			const periodeLvl = last(row._path)!.c.find((lvl) => lvl.k === periode);
			if (row._id === DUMMY_ROW_ID) {
				// wordt aangeroepen bij sorteren
				// sorteren heeft hier alleen zin op rijniveau en bij aanwezige periode
				if (row._path.length !== groupNames.length + 1 || !periodeLvl) return 0;

				return avgCijfer([...row._path, periodeLvl]);
			}
			if (!periodeLvl) return [];

			return fill(
				periodeLvl.r,
				start,
				end,
				(path) => {
					const attrs = getInitialAttributes<ToetsI>(subgroupNames, measureNames, path);
					return this.makeToetsData(attrs, path, context);
				},
				() => null
			);
		};
	}

	protected makeToetsData(attrs: ToetsI, path: Path<ToetsA, number[]>, context: DashboardContext<ToetsI, ToetsA, ToetsComponent>): ToetsData {
		const perc_onvoldoende = percOfRow('onvoldoendes', 'cf_is_gevuld')(path);
		const linkData = context.config.createLinkData(path, context);
		const {
			cf_nr_cijfer,
			cf_nr_decimalen,
			clc_abb_label_avg,
			clc_is_voldoende_avg,
			cf_abb_kolomkop_agg,
			cf_co_kolom_agg,
			cf_des_kolom_agg,
			cf_nr_leerlingen,
			cf_nr_weging_agg,
			cf_nm_bijzonderheid,
			cf_nm_kolomtype,
		} = attrs;
		return {
			cf_nr_cijfer,
			cf_nr_decimalen,
			clc_abb_label_avg,
			clc_is_voldoende_avg,
			cf_nm_bijzonderheid,
			cf_nm_kolomtype,
			abb_kolomkop: cf_abb_kolomkop_agg,
			co_kolom: cf_co_kolom_agg,
			des_kolom: cf_des_kolom_agg,
			nr_leerlingen: cf_nr_leerlingen,
			nr_weging: cf_nr_weging_agg,
			perc_onvoldoende,
			linkData,
		};
	}

	createMeasureColumns(context: DashboardContext<ToetsI, ToetsA, ToetsComponent>): ColumnDef<DataRow<ToetsA>>[] {
		const lln = createMeasureColumn('lln', att('cf_nr_leerlingen'), {
			context,
			clickHandler: (rowModel) => this.handleLeerlingenRedirect(rowModel, context),
		});
		lln.header.class = 'lln-header';
		return [lln];
	}

	private handleLeerlingenRedirect(rowModel: DataRow<ToetsA>, context: DashboardContext<ToetsI, ToetsA, ToetsComponent>) {
		const linkData = super.createLinkData(rowModel._path, context);
		this.urlService.navigate({
			...linkData,
			dashboard: '/details/leerling/cijferlijst',
			dataProvider: 'cijfers',
		});
	}

	createLinkData(path: Path<ToetsA, number[]>, context: DashboardContext<ToetsI, ToetsA, ToetsComponent>): Partial<LinkData> {
		const groups = [...context.groupNames];
		const linkData = super.createLinkData(path, context);
		const toetsFilter = new CompoundFilterExpression([
			<FilterExpression>linkData.filter!,
			new BasicFilterExpression(['cf_nm_leerling'], last(path)!.k),
		]);

		return this.createZoomLinkData(
			['cf_nm_lesgroep', 'cf_fks_mw.mw_nm_medewerker', 'cf_nm_leerling'],
			groups,
			path,
			{
				...linkData,
				dashboard: '/details/leerling/cijferlijst',
				dataProvider: 'cijfers',
				filter: toetsFilter,
			},
			{ ignoreFirst: 2 }
		);
	}

	enrichTableModel(tableModel: TableModel<DataRow<ToetsA>>) {
		tableModel.showFooters = false;
	}

	ngOnInit(): void {
		this.subscribeToQueryParams();
	}

	subscribeToQueryParams() {
		this.subscriptions.push(
			this.qp.observe_g().subscribe((groups) => (this.groups = groups ?? [])),
			this.qp.observe('cijfertype').subscribe((cijfertype) => (this.cijferkolomKeuze = cijfertype)),
			this.qp.observe('dossier').subscribe((dossier) => (this.dossier = dossier)),
			this.userService.instelling$.subscribe((instelling) => (this.bron = instelling.bron))
		);
	}

	factTable = FactTable.cijfers;

	getData(options: DataOptions): Observable<DataResponse<number[]>> {
		return this.dataService.getCijfersData({
			...options,
			m: [CijferMeasure.STATS, CijferMeasure.LABEL, CijferMeasure.BIJZONDERHEID, CijferMeasure.TOETS],
			r: [0, ToetsComponent._determineGroups(this.groups).length + 1],
		});
	}

	getExportData(options: ExportDataOptions) {
		return this.dataService.getCijfersExportData(options);
	}

	getSelectedDossierOptie() {
		return this.dossierOpties.find((optie) => optie.value === this.dossier)!;
	}

	getSelectedCijfertypeOptie() {
		return this.cijferkolomKeuzeOpties().find((optie) => optie.value === this.cijferkolomKeuze);
	}

	// memoize, otherwise new array keeps triggering change detection
	getPermanentFilters = memoize(this._getPermanentFilters, (c, d, g) => JSON.stringify([c, d, g]));

	protected _getPermanentFilters(cijferkolomKeuze: CijferkolomKeuze, dossier: Dossier, groups: AttrPath[]): FilterExpression[] {
		const filters: FilterExpression[] = [];
		if (this.bron === InstellingBron.Somtoday && cijferkolomKeuze !== CijferkolomKeuze.GEMIDDELDE) {
			filters.push(
				dossier === Dossier.VOORTGANG
					? new BasicFilterExpression(['cf_is_voortgangsdossier'], 1)
					: new BasicFilterExpression(['cf_is_examendossier'], 1)
			);
		}

		switch (cijferkolomKeuze) {
			case CijferkolomKeuze.ALLE:
				if (!groups.map((group) => group.join('.')).includes('cf_nm_leerling'))
					filters.push(new BasicFilterExpression(['cf_nm_kolomtype'], Cijferkolomtype.ADVIES, '<>'));
				break;
			case CijferkolomKeuze.TOETS:
				filters.push(new BasicFilterExpression(['cf_nm_kolomtype'], Cijferkolomtype.TOETS, '='));
				break;
			case CijferkolomKeuze.GEMIDDELDE:
				filters.push(
					new InFilterExpression(
						['cf_nm_kolomtype'],
						Object.values(Cijferkolomtype).filter((value) => ![Cijferkolomtype.ADVIES, Cijferkolomtype.TOETS].includes(value))
					)
				);
				break;
			case CijferkolomKeuze.ADVIES:
				filters.push(new BasicFilterExpression(['cf_nm_kolomtype'], Cijferkolomtype.ADVIES, '='));
				break;
			default:
		}
		return filters;
	}

	getDisplayOptions(): ExportFilter[] {
		return [
			...(this.bron === InstellingBron.Somtoday ? [{ label: 'Toetsdossier', value: this.dossier }] : []),
			{ label: 'Cijfertype', value: this.cijferkolomKeuze === CijferkolomKeuze.ALLE ? 'Alle' : this.cijferkolomKeuze },
		];
	}

	// memoize, otherwise new array keeps triggering change detection
	determineGroups = memoize(ToetsComponent._determineGroups, JSON.stringify);

	private static _determineGroups(groups: AttrPath[]): AttrPath[] {
		return [['cf_nm_vak'], ...groups];
	}

	protected singleAggregators: Partial<{ [ai in keyof ToetsA]: SingleAggregator<ToetsI, ToetsA[ai]> }> = {
		cf_nr_leerlingen: noAgg('cf_nr_leerlingen'),
		gem_cijfer: noAgg('cf_nr_cijfer'),
		sum_cijfer: {
			init: ({ cf_nr_cijfer, cf_is_gevuld }) => cf_nr_cijfer * cf_is_gevuld,
			combine: (as) => sum(as),
		},
		cf_is_gevuld: sumOver('cf_is_gevuld'),
		onvoldoendes: {
			init: ({ cf_is_voldoende, cf_is_gevuld }) => cf_is_gevuld - cf_is_voldoende,
			combine: (as) => sum(as),
		},
	};

	public sortToetsKolommen(colPath: (string | null)[], keys0: (string | null)[], keys1: (string | null)[]): (string | null)[] {
		const allKeys = union(keys0, keys1);

		//cf_nm_kolomtype
		if (colPath.length == 2) {
			return intersection(
				[
					Cijferkolomtype.TOETS,
					Cijferkolomtype.ADVIES,
					Cijferkolomtype.GEMIDDELDE,
					Cijferkolomtype.RAPPORTCIJFER,
					Cijferkolomtype.SE_CIJFER,
					Cijferkolomtype.CE_CIJFER,
					Cijferkolomtype.EINDCIJFER,
				],
				allKeys
			);
		}

		const [otherKeys, numberKeys] = partition(allKeys, (k) => isNaN(Number(k)));
		return [...sortBy(numberKeys, [Number]), ...sortBy(otherKeys)];
	}
}

// alleen bedoeld om te sorteren
function avgCijfer(path: Path<ToetsA, unknown>) {
	const { sum_cijfer, cf_is_gevuld } = getLeafA(path);
	return deelVeilig(sum_cijfer ?? 0, cf_is_gevuld);
}

/**
 * Vult een gehele rij, op basis van gedeeltelijk aanwezige records. Niet-aanwezige records krijgen een placeholder.
 *
 * @param paths De records, met op hun laagste niveau in het "i" veld de kolom-positie.
 * @param start "i" index voor de eerste kolom.
 * @param end "i" index voor de laatste kolom + 1
 * @param mapVal produceert een waarde voor een bestaand record
 * @param fillVal produceert een placeholder voor een niet-bestaand record
 */
function fill<A, D, T>(paths: Path<A, D>[], start: number, end: number, mapVal: (path: Path<A, D>) => T, fillVal: (ix: number) => T): T[] {
	const present: { [n: number]: T } = {};
	for (const path of paths) {
		present[last(path)!.i] = mapVal(path);
	}
	return range(start, end).map((i) => present[i] ?? fillVal(i));
}
