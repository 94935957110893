import { Component, OnInit, ViewChild } from '@angular/core';
import { isUndefined, memoize } from 'lodash-es';
import { firstValueFrom, Observable, ReplaySubject } from 'rxjs';
import { aggArrayString, maxOver, SingleAggregator } from '../../../services/aggregation';
import {
	AttrPath,
	BasicFilterExpression,
	DataOptions,
	DataResponse,
	DataService,
	ExportDataOptions,
	FilterExpression,
} from '../../../services/data.service';
import { FilterService } from '../../../services/filter.service';
import { QueryParamStateService } from '../../../services/query-param-state.service';
import { ColumnType, DataRow } from '../../../shared/dashboard/data-tree-table/data-tree-table';
import { DashboardLeerling, LeerlingDetail } from '../../Details';
import { DetailsDashboard } from '../../base-details-panel/details.dashboard';
import { FilterName } from '../../../services/filter-config';
import { BarchartTableConfig } from '../../../shared/dashboard/barchart-table/barchart-table-config';
import { Attributes } from '../../../shared/dashboard/base-dashboard/base-dashboard-config';
import { ExportOptions } from '../../../services/export.service';
import { FactTable } from '../../../services/exportable';
import { ToastrService } from 'ngx-toastr';
import { DashboardContext } from '../../../shared/dashboard/base-dashboard/dashboard-context';
import { AsyncPipe } from '@angular/common';
import { BarchartTableComponent } from '../../../shared/dashboard/barchart-table/barchart-table.component';
import { DashboardHeaderComponent } from '../../../dashboard-header/dashboard-header.component';
import { FilterPanelComponent } from '../../../filter-panel/filter-panel.component';
import { TableModel } from '../../../shared/components/table/table/table.model';

interface VakkenA extends Attributes {
	max: number;
	vkk_nm_opleidingen: string;
}

interface VakkenI extends Attributes {
	vkk_nm_opleidingen: string;
}

@Component({
	selector: 'app-leerling-vakken-details-panel',
	templateUrl: './leerling-vakken-details-panel.component.html',
	styleUrls: ['./leerling-vakken-details-panel.component.scss'],
	standalone: true,
	imports: [FilterPanelComponent, DashboardHeaderComponent, BarchartTableComponent, AsyncPipe],
})
export class LeerlingVakkenDetailsPanelComponent extends BarchartTableConfig<VakkenI, VakkenA> implements OnInit, DetailsDashboard<LeerlingDetail> {
	leerling$ = new ReplaySubject<DashboardLeerling>(1);

	fixedBeforeGroups = 4;

	flexibleMaxGroups = 0;

	filterExpressions?: FilterExpression[];

	filters: FilterName[] = ['vkk_nm_schooljaar', 'x_vakkeuze_peildatum'];

	groups: AttrPath[] = [['vkk_nm_soort_vakpositie'], ['vkk_nm_vak'], ['vkk_nm_lesgroep'], ['vkk_fk_lg', 'lg_nm_docenten']];

	opleiding?: string;

	@ViewChild(DashboardHeaderComponent)
	dashboardHeaderComponent?: DashboardHeaderComponent;

	constructor(
		protected dataService: DataService,
		protected filterService: FilterService,
		public qp: QueryParamStateService,
		protected toastr: ToastrService
	) {
		super(filterService, toastr);
	}

	columnOrder: ColumnType[] = [ColumnType.GROUP];

	protected singleAggregators: Partial<{ [ai in keyof VakkenA]: SingleAggregator<VakkenI, VakkenA[ai]> }> = {
		max: maxOver<'count_records', VakkenI>('count_records'),
		vkk_nm_opleidingen: aggArrayString('vkk_nm_opleidingen'),
	};

	ngOnInit() {
		this.subscriptions.push(this.filterService.observe('vkk_nm_schooljaar').subscribe((val) => this.qp.dispatch('schooljaar', val)));
	}

	onContextCreated(context: DashboardContext<VakkenI, VakkenA, LeerlingVakkenDetailsPanelComponent>) {
		const dataRoot = context.dataRoot;
		if (isUndefined(dataRoot)) {
			this.opleiding = 'Onbekend';
			return;
		}

		this.opleiding = dataRoot.a.vkk_nm_opleidingen;
	}

	setSelected(selected: LeerlingDetail, schooljaar?: string): void {
		this.leerling$.next({
			leerling: selected,
			schooljaarInfo: schooljaar ?? selected.lb_nm_schooljaar,
		});
	}

	doDetailsExport(exportOptions: ExportOptions) {
		firstValueFrom(this.leerling$).then((leerling) =>
			this.doExport(this.filterExpressions!, this.getDashboardFilters(leerling.leerling), [], exportOptions)
		);
	}

	getExportData(options: ExportDataOptions): Observable<Blob> {
		return this.dataService.getVakkeuzeExportData(options);
	}

	getData(options: DataOptions): Observable<DataResponse<number[]>> {
		return this.dataService.getVakkeuzeData(options);
	}

	factTable = FactTable.vakkeuze;

	getDashboardFilters = memoize(LeerlingVakkenDetailsPanelComponent._getDashboardFilters, (l) => l.lb_nr_leerling);

	private static _getDashboardFilters(leerling: LeerlingDetail): FilterExpression[] {
		return [new BasicFilterExpression(['vkk_nr_leerling'], leerling.lb_nr_leerling)];
	}

	getSchooljaarFilterOverride = memoize(LeerlingVakkenDetailsPanelComponent._getSchooljaarFilterOverride);

	private static _getSchooljaarFilterOverride(schooljaarInfo: string) {
		return { vkk_nm_schooljaar: schooljaarInfo };
	}

	enrichTableModel(tableModel: TableModel<DataRow<VakkenA>>) {
		tableModel.showFooters = false;
	}
}
