import { Injectable, OnDestroy } from '@angular/core';
import { Observable, ReplaySubject, Subscription } from 'rxjs';
import { AuthService } from '@cumlaude/shared-authentication';
import { RestService } from '@cumlaude/shared-services';
import {
	InstellingBron,
	RCumLaudeAccount,
	RCumLaudeAccountAdditionalObjectKey,
	RCumLaudeModule,
	RInstelling,
	RRol,
	RVestiging,
} from '@cumlaude/service-contract';
import { intersection, isNil, isUndefined } from 'lodash-es';
import { Attr, AttrPath } from './data.service';
import { filter, map, shareReplay, switchMap } from 'rxjs/operators';
import { Router, UrlTree } from '@angular/router';
import { ExtraFilterNames, FilterName } from './filter-config';
import { bestuurBlocklist } from './bestuur-blocklist';
import { magisterBlocklist } from './magister-blocklist';
import { geenVSOBlocklist } from './geen-vso-blocklist';
import { geenVOBlocklist } from './geen-vo-blocklist';
import { somtodayBlocklist } from './somtoday-blocklist';
import { UserBugsnagService } from '@cumlaude/user-bugsnag';

interface UrlAllowed {
	name: string;

	allowed?: (vo: boolean, vso: boolean) => boolean;
	sub?: UrlAllowed[];
}

const schoolbreedTeamleiderAllowed: UrlAllowed[] = [
	{ name: 'afwezigheid' },
	{ name: 'cijfers' },
	{
		name: 'details',
		sub: [
			{ name: 'leerling' },
			{ name: 'docent' },
			{
				name: 'uitzondering',
				sub: [
					{ name: 'doorstroom' },
					{ name: 'onderbouwsnelheid', allowed: (vo, _vso) => vo },
					{ name: 'onderwijspositie', allowed: (vo, _vso) => vo },
					{ name: 'bovenbouwsucces', allowed: (vo, _vso) => vo },
					{ name: 'einduitstroom', allowed: (_vo, vso) => vso },
					{ name: 'uitstroom-iq', allowed: (_vo, vso) => vso },
					{ name: 'tussentijdse-uitstroom', allowed: (_vo, vso) => vso },
				],
			},
		],
	},
	{ name: 'doorstroom' },
	{ name: 'leerling' },
	{ name: 'onderwijsresultaten', allowed: (vo, _vso) => vo },
	{ name: 'prestatieanalyse', allowed: (_vo, vso) => vso },
	{ name: 'basisvaardigheden' },
];

const allowedMap: Partial<{ [rol in RRol]: UrlAllowed[] }> = {
	[RRol.SCHOOLBREED]: schoolbreedTeamleiderAllowed,
	[RRol.TEAMLEIDER]: schoolbreedTeamleiderAllowed,
	[RRol.BESTUUR]: [
		{ name: 'cijfers', sub: [{ name: 'overzicht' }, { name: 'se-ce' }, { name: 'examens' }] },
		{
			name: 'details',
			sub: [
				{
					name: 'uitzondering',
					sub: [
						{ name: 'doorstroom' },
						{ name: 'onderbouwsnelheid', allowed: (vo, _vso) => vo },
						{ name: 'onderwijspositie', allowed: (vo, _vso) => vo },
						{ name: 'bovenbouwsucces', allowed: (vo, _vso) => vo },
						{ name: 'einduitstroom', allowed: (_vo, vso) => vso },
						{ name: 'uitstroom-iq', allowed: (_vo, vso) => vso },
						{ name: 'tussentijdse-uitstroom', allowed: (_vo, vso) => vso },
					],
				},
			],
		},
		{ name: 'doorstroom' },
		{ name: 'leerling' },
		{ name: 'onderwijsresultaten', allowed: (vo, _vso) => vo },
		{ name: 'prestatieanalyse', allowed: (_vo, vso) => vso },
		{ name: 'basisvaardigheden' },
	],
	[RRol.SECTIELEIDER]: [
		{ name: 'afwezigheid' },
		{ name: 'cijfers' },
		{
			name: 'details',
			sub: [
				{
					name: 'leerling',
					sub: [{ name: 'afwezigheid' }, { name: 'afwezigheidsredenen' }, { name: 'cijferlijst' }, { name: 'lesregistraties' }],
				},
				{ name: 'docent' },
			],
		},
	],
	[RRol.DOCENT]: [
		{ name: 'cijfers', sub: [{ name: 'overzicht' }, { name: 'toets' }, { name: 'toets-details' }, { name: 'se-ce' }, { name: 'examens' }] },
		{
			name: 'details',
			sub: [
				{ name: 'leerling', sub: [{ name: 'cijferlijst' }, { name: 'basisvaardigheden' }] },
				{ name: 'docent', sub: [{ name: 'toets' }] },
			],
		},
	],
	[RRol.MENTOR]: [
		{ name: 'afwezigheid' },
		{ name: 'cijfers', sub: [{ name: 'overzicht' }, { name: 'toets' }, { name: 'cijferlijst' }] },
		{ name: 'details', sub: [{ name: 'leerling' }] },
	],
};

export const rolOrder: RRol[] = [RRol.SCHOOLBREED, RRol.TEAMLEIDER, RRol.SECTIELEIDER, RRol.DOCENT, RRol.MENTOR, RRol.BESTUUR];

export function rollenForModules(bestuurEnabled: boolean, modules: RCumLaudeModule[]): RRol[] {
	let rollen = [RRol.SCHOOLBREED, RRol.TEAMLEIDER];

	if (modules.includes(RCumLaudeModule.SECTIE)) rollen.push(RRol.SECTIELEIDER);
	if (modules.includes(RCumLaudeModule.DOCENT)) rollen.push(RRol.DOCENT, RRol.MENTOR);
	if (bestuurEnabled) rollen.push(RRol.BESTUUR);

	return rollen;
}

@Injectable({
	providedIn: 'root',
})
export class UserService implements OnDestroy {
	private rollen: RRol[] = [];

	rolChanged$ = new ReplaySubject<RRol>(1);

	rollen$: Observable<RRol[]>;

	/** Lijst vestigingen uit de REST-service. Geeft alleen vestigingen waarvoor CumLaude actief is. Bevat ook de bijbehorende BRIN-nummers. */
	rVestigingen$: Observable<RVestiging[]>;

	myAccount$: Observable<RCumLaudeAccount>;

	instelling$: Observable<RInstelling>;

	isSomtoday$: Observable<boolean>;

	modules$: Observable<RCumLaudeModule[]>;

	private vso: boolean = false;

	private vo: boolean = false;

	useBestuurBlocklist = false;
	useMagisterBlockList = false;
	useSomtodayBlockList = false;
	useNoVSOBlockList = false;
	useNoVOBlockList = false;

	bron!: InstellingBron;

	private subscriptions: Subscription[] = [];

	constructor(
		authService: AuthService,
		restService: RestService,
		bugsnag: UserBugsnagService,
		private router: Router
	) {
		this.initActiveRol();
		this.myAccount$ = authService.loggedIn$.pipe(
			filter((x) => x),
			switchMap(() => restService.getMyAccount()),
			shareReplay(1)
		);
		this.instelling$ = this.myAccount$.pipe(switchMap((account) => restService.getInstelling(account.instelling)));
		this.isSomtoday$ = this.instelling$.pipe(map((instelling) => instelling.bron === InstellingBron.Somtoday));
		this.modules$ = this.instelling$.pipe(map((instelling) => instelling.modules));
		this.rVestigingen$ = this.rolChanged$.pipe(
			switchMap((rol) => restService.getVestigingen({ cumlaudeActief: rol !== RRol.BESTUUR, vanBestuur: rol === RRol.BESTUUR })),
			shareReplay(1)
		);
		this.rollen$ = this.myAccount$.pipe(
			map(({ additionalObjects }) => additionalObjects![RCumLaudeAccountAdditionalObjectKey.AUTORISATIES].rollen)
		);

		this.subscriptions.push(
			this.rollen$.subscribe((rollen) => {
				this.rollen = rollen;

				const activeRol = this.getActiveRol();
				if (isUndefined(activeRol) || !this.rollen.includes(activeRol)) this.changeRol(intersection(rolOrder, this.rollen)[0]);
			}),
			this.rVestigingen$.subscribe((vestigingen) => {
				this.vso = vestigingen.some((vestiging) => vestiging.vso);
				this.vo = vestigingen.some((vestiging) => vestiging.vo);
				this.useNoVSOBlockList = !this.vso;
				this.useNoVOBlockList = !this.vo;
			}),
			this.rolChanged$.subscribe((rol) => {
				bugsnag.updateRol(rol);
				this.useBestuurBlocklist = rol === RRol.BESTUUR;
			}),
			this.myAccount$.subscribe((account) => bugsnag.updateAccount(account)),
			this.instelling$.subscribe((instelling) => {
				bugsnag.updateInstelling(instelling);
				this.bron = instelling.bron;
				this.useMagisterBlockList = this.bron === InstellingBron.Magister;
				this.useSomtodayBlockList = this.bron === InstellingBron.Somtoday;
			})
		);
	}

	/**
	 * Wijzigt de rol in de session storage en brengt dan alle onderdelen die er van afhankelijk zijn op de hoogte dat de rol gewijzigd is.
	 */
	changeRol(rol: RRol) {
		if (isUndefined(rol)) sessionStorage.removeItem('rol');
		else sessionStorage.setItem('rol', rol);

		this.rolChanged$.next(rol);
	}

	isAttAllowed(name: FilterName) {
		return name.split('.').every((attr) => this.isAttrAllowed(<Attr | ExtraFilterNames>attr));
	}

	isAttrPathAllowed(attrPath: AttrPath) {
		return attrPath.every((attr) => this.isAttrAllowed(attr));
	}

	isAttrAllowed(attr: Attr | ExtraFilterNames) {
		const bestuur = !this.useBestuurBlocklist || !bestuurBlocklist.includes(attr);
		const magister = !this.useMagisterBlockList || !magisterBlocklist.includes(attr);
		const somtoday = !this.useSomtodayBlockList || !somtodayBlocklist.includes(attr);
		const noVSO = !this.useNoVSOBlockList || !geenVSOBlocklist.includes(attr);
		const noVO = !this.useNoVOBlockList || !geenVOBlocklist.includes(attr);
		return bestuur && magister && somtoday && noVO && noVSO;
	}

	/**
	 * Vertaalt de rol enum naar de naam die de gebruiker kent.
	 */
	getRolName(rol: RRol): string {
		switch (rol) {
			case RRol.DOCENT:
				return 'Docent';
			case RRol.SECTIELEIDER:
				return 'Sectie';
			case RRol.SCHOOLBREED:
				return 'School';
			case RRol.TEAMLEIDER:
				return 'Team';
			case RRol.MENTOR:
				return 'Mentor';
			case RRol.BESTUUR:
				return 'Bestuur';
			default:
				return '';
		}
	}

	/**
	 * Vertaalt de rol enum naar de titel van de gebruiker, indien mogelijk.
	 */
	getRolDescription(rol: RRol | undefined): string {
		switch (rol) {
			case RRol.SCHOOLBREED:
				return 'Schoolbreed';
			case RRol.TEAMLEIDER:
				return 'Teamleider';
			case RRol.SECTIELEIDER:
				return 'Sectieleider';
			case RRol.DOCENT:
				return 'Docent';
			case RRol.MENTOR:
				return 'Mentor';
			case RRol.BESTUUR:
				return 'Bestuur';
			default:
				return '';
		}
	}

	getAccountRolDescription(rol: RRol | undefined, account: RCumLaudeAccount): string {
		const rolDescription = this.getRolDescription(rol);

		let autorisaties: string[] = [];
		const autorisatieData = account.additionalObjects![RCumLaudeAccountAdditionalObjectKey.AUTORISATIES];
		switch (rol) {
			case RRol.TEAMLEIDER:
				autorisaties = autorisatieData.teamleider;
				break;
			case RRol.SECTIELEIDER:
				autorisaties = autorisatieData.sectieleider;
				break;
			case RRol.DOCENT:
				autorisaties = autorisatieData.docent;
				break;
			case RRol.MENTOR:
				autorisaties = autorisatieData.mentor;
				break;
		}

		if (autorisaties.length > 0) return `${rolDescription}: ${autorisaties.join(', ')}`;
		return rolDescription;
	}

	/**
	 * Initialiseert de diverse onderdelen wat de rol is die uit de session storage komt.
	 */
	private initActiveRol() {
		const activeRol = this.getActiveRol();
		if (activeRol) this.changeRol(activeRol);
	}

	/**
	 * Geeft de actieve rol terug uit de session storage.
	 */
	getActiveRol(): RRol | undefined {
		const rol = sessionStorage.getItem('rol');
		if (!isNil(rol)) return <RRol>rol;
		return undefined;
	}

	/**
	 * Checkt of een gebruiker bij een bepaalde url mag komen.
	 * Geeft true of false terug.
	 */
	isUrlAllowed(urlRequested: string): boolean {
		const result = this.checkUrlForRol(urlRequested);
		return result instanceof UrlTree ? true : result;
	}

	isUrlAllowedForRollen(urlRequested: string, rollen: RRol[]): boolean {
		for (let rol of rollen) {
			const allowedList = allowedMap[rol];
			if (!allowedList) continue;

			const allowed = this.checkAllowedForUrl(urlRequested, allowedList);
			if (allowed) return true;
		}

		return false;
	}

	/**
	 * Checkt of een gebruiker bij een bepaalde url mag komen.
	 * Geeft true of false terug indien de gebruiker hier wel of niet bij mag komen.
	 * Indien alleen bij specifieke suburls, geeft dan een UrlTree terug naar die specifieke suburl.
	 */
	checkUrlForRol(urlRequested: string): boolean | UrlTree {
		if (this.isAllowedWithoutRol(urlRequested)) return true;
		if (this.isBeheerAllowed(urlRequested)) return true;

		const activeRol = this.getActiveRol();
		if (isUndefined(activeRol)) return false;

		const allowedList = allowedMap[activeRol];
		if (allowedList) return this.checkAllowedForUrl(urlRequested, allowedList);

		return false;
	}

	/**
	 * Zonder actieve rol mag je bij root en alles onder dashboard.
	 */
	private isAllowedWithoutRol(urlRequested: string): boolean {
		return urlRequested.startsWith('/dashboard') || urlRequested.startsWith('/shared') || urlRequested == '/' || urlRequested == '';
	}

	/**
	 * Als je de rol beheerder hebt toegewezen gekregen, mag je bij alles dat onder /beheer valt.
	 */
	private isBeheerAllowed(urlRequested: string) {
		return (
			this.rollen.includes(RRol.BEHEERDER) &&
			urlRequested.startsWith('/beheer') &&
			!(
				this.bron !== InstellingBron.Magister &&
				(urlRequested.startsWith('/beheer/cijferinstellingen') || urlRequested.startsWith('/beheer/vakken_uitsluiten'))
			)
		);
	}

	/**
	 * Haalt de slash aan het begin weg, splits de url vervolgens in stukjes en start daarna het checken van de url.
	 */
	private checkAllowedForUrl(urlRequested: string, urlAllowed: UrlAllowed[]): boolean | UrlTree {
		const urlParts = urlRequested.substring(1).split('/');
		return this.checkUrlAllowed(urlRequested, urlParts, urlAllowed);
	}

	/**
	 * Doet een aantal checks op de url om te controleren of de gebruiker de url mag gebruiken.
	 *  1. Is het eerste deel van de url sowieso toegestaan voor de gebruiker.
	 *  2. Check of de gebruiker's school matched met het gewenste onderwijstype bij specifieke domeinen.
	 *  3. Checkt of de url toestemming gaat over de alle suburl's of alleen specifieke suburl's.
	 *  4. Checkt of we nu onderaan de url zijn en toch nog suburl's als opties hebben, zo ja, redirecten naar eerste suburl.
	 *  5. Vervolgt bij de volgende url deel met checks.
	 */
	private checkUrlAllowed(urlRequested: string, urlParts: string[], urlAllowed: UrlAllowed[]): boolean | UrlTree {
		const urlPart = urlParts[0];

		const allowedUrl = urlAllowed.find((part) => part.name == urlPart);
		if (!allowedUrl) return false;

		if (allowedUrl.allowed && !allowedUrl.allowed(this.vo, this.vso)) return false;

		if (!allowedUrl.sub) return true;

		if (urlParts.length == 1) {
			const dashboard = allowedUrl.sub[0].name;
			return this.router.parseUrl(`${urlRequested}/${dashboard}`);
		}

		return this.checkUrlAllowed(urlRequested, urlParts.slice(1), allowedUrl.sub);
	}

	ngOnDestroy() {
		for (const sub of this.subscriptions) sub.unsubscribe();
	}
}
