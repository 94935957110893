import { AsyncPipe, formatDate, formatPercent } from '@angular/common';
import { Component, OnInit, ViewChild } from '@angular/core';
import { isArray, isEqual, memoize } from 'lodash-es';
import { lastValueFrom, Observable, of, ReplaySubject } from 'rxjs';
import { CalendarComponent, Day } from '../../../calendar/calendar.component';
import { DataTree, NULL_STRING } from '../../../services/data-tree';
import {
	AanwezigheidMeasure,
	BasicFilterExpression,
	CompoundFilterExpression,
	DataResponse,
	DataService,
	ExportDataOptions,
	FilterExpression,
} from '../../../services/data.service';
import { FilterService } from '../../../services/filter.service';
import { QueryParamStateService } from '../../../services/query-param-state.service';
import { getSchooljaarBegin } from '@cumlaude/shared-utils';
import { DashboardLeerling, LeerlingDetail } from '../../Details';
import { DetailsDashboard } from '../../base-details-panel/details.dashboard';
import { FilterName } from '../../../services/filter-config';
import { take } from 'rxjs/operators';
import { ExportOptions, ExportType } from '../../../services/export.service';
import { Dashboard } from '../../../shared/dashboard/base-dashboard/dashboard';
import { FactTable } from '../../../services/exportable';
import { ToastrService } from 'ngx-toastr';
import { TooltipElement } from '@cumlaude/shared-components-overlays';
import { DashboardHeaderComponent } from '../../../dashboard-header/dashboard-header.component';
import { FilterPanelComponent } from '../../../filter-panel/filter-panel.component';

@Component({
	selector: 'app-leerling-afwezigheid-details-panel',
	templateUrl: './leerling-afwezigheid-details-panel.component.html',
	styleUrls: ['./leerling-afwezigheid-details-panel.component.scss'],
	standalone: true,
	imports: [FilterPanelComponent, DashboardHeaderComponent, CalendarComponent, AsyncPipe],
})
export class LeerlingAfwezigheidDetailsPanelComponent extends Dashboard implements OnInit, DetailsDashboard<LeerlingDetail> {
	leerling$ = new ReplaySubject<DashboardLeerling>(1);

	filters: FilterName[] = ['aw_nm_schooljaar'];

	filterExpressions?: FilterExpression[];

	@ViewChild(DashboardHeaderComponent)
	dashboardHeaderComponent?: DashboardHeaderComponent;

	constructor(
		protected dataService: DataService,
		protected filterService: FilterService,
		public qp: QueryParamStateService,
		protected toastr: ToastrService
	) {
		super(filterService, toastr);
	}

	exportTypes = [ExportType.AFBEELDING, ExportType.PDF, ExportType.DATA];

	ngOnInit(): void {
		this.subscriptions.push(this.filterService.observe('aw_nm_schooljaar').subscribe((val) => this.qp.dispatch('schooljaar', val)));
	}

	getSchooljaar() {
		return this.filterExpressions
			?.filter((value) => value instanceof BasicFilterExpression)
			.find((value) => isEqual((<BasicFilterExpression<any>>value).attr, ['aw_nm_schooljaar']));
	}

	setSelected(selected: LeerlingDetail, schooljaar?: string): void {
		this.leerling$.next({
			leerling: selected,
			schooljaarInfo: schooljaar ?? selected.lb_nm_schooljaar,
		});
	}

	doDetailsExport(exportOptions: ExportOptions) {
		lastValueFrom(this.leerling$.pipe(take(1))).then((leerling) =>
			this.doExport(this.filterExpressions!, this.getDashboardFilters(leerling.leerling), [], exportOptions)
		);
	}

	getExportData(options: ExportDataOptions): Observable<Blob> {
		return this.dataService.getAanwezigheidExportData(options);
	}

	getBeginDateForSchooljaar() {
		const schooljaar = this.getSchooljaar();
		return of(getSchooljaarBegin(schooljaar!.getValueString()));
	}

	getData = memoize(this._getData, (f, pf) => JSON.stringify([f, pf]));

	private _getData(filterExpressions: FilterExpression[], permanentFilterExpressions: FilterExpression[]): Observable<DataResponse<number[]>> {
		return this.dataService.getAanwezigheidData({
			g: [['aw_d_datum'], ['aw_is_abs_geoorloofd']],
			f: new CompoundFilterExpression([...filterExpressions, ...permanentFilterExpressions]),
			m: [AanwezigheidMeasure.LESUREN],
		});
	}

	factTable = FactTable.aanwezigheid;

	getDashboardFilters = memoize(LeerlingAfwezigheidDetailsPanelComponent._getDashboardFilters, (l) => l.lb_nr_leerling);

	private static _getDashboardFilters(leerling: LeerlingDetail): FilterExpression[] {
		return [new BasicFilterExpression(['aw_nr_leerling'], leerling.lb_nr_leerling)];
	}

	getSchooljaarFilterOverride = memoize(LeerlingAfwezigheidDetailsPanelComponent._getSchooljaarFilterOverride);

	private static _getSchooljaarFilterOverride(schooljaarInfo: string) {
		return { aw_nm_schooljaar: schooljaarInfo };
	}

	generateDay(date: Date, { data }: DataResponse<number[]>): Day {
		const dayData = (<Map<string, DataTree<number[]>>>data).get(formatDate(date, 'yyyy-MM-dd', 'nl-NL'));
		if (!dayData) return { date: new Date(date), classNames: { geenLes: true }, tooltip: 'Geen les' };

		const geoorloofd = getCount(dayData, '1');
		const ongeoorloofd = getCount(dayData, '0');
		const aanwezig = getCount(dayData, NULL_STRING);
		const totaal = aanwezig + geoorloofd + ongeoorloofd;
		const classNames = {
			geoorloofd: geoorloofd > 0,
			ongeoorloofd: ongeoorloofd > 0,
		};

		const tooltip: TooltipElement[] = [];
		tooltip.push({
			label: 'Aantal lesuren',
			value: `${totaal}`,
		});

		if (geoorloofd > 0) {
			tooltip.push({
				label: 'Geoorloofd afwezig',
				value: `${geoorloofd} (${formatPercent(geoorloofd / totaal, 'nl-NL', '1.0-1')})`,
			});
		}

		if (ongeoorloofd > 0) {
			tooltip.push({
				label: 'Ongeoorloofd afwezig',
				value: ` ${ongeoorloofd} (${formatPercent(ongeoorloofd / totaal, 'nl-NL', '1.0-1')})`,
			});
		}

		return { date: new Date(date), classNames, tooltip };
	}
}

function getCount(dayData: DataTree<number[]> | undefined, key: string): number {
	if (dayData instanceof Map) {
		const record: Map<string, DataTree<number[]>> = <Map<string, DataTree<number[]>>>dayData.get(key);
		if (isArray(record)) return record[0];
	}
	return 0;
}
