import { Categorie } from './exportable';

export const loopbaan_examen: Categorie = {
	label: 'Examen',
	atts: [
		{ att: 'lb_nm_examenstatus', example: 'Geslaagd, Afgewezen, Voortijdig teruggetrokken, ...' },
		{ att: 'lb_d_examen', example: '13-06-2023' },
		{ att: 'lb_is_examenkandidaat', example: 'Ja, Nee' },
		{ att: 'lb_is_examenopleiding', example: 'Ja, Nee' },
	],
};

export const loopbaan_kenmerk: Categorie = {
	label: 'Kenmerk',
	atts: [
		{ att: 'lb_is_apcg', example: 'Ja, Nee' },
		{ att: 'lb_is_examenopstroom', example: 'Ja, Nee' },
		{ att: 'lb_is_nieuwkomer', example: 'Ja, Nee' },
	],
};

export const loopbaan_plaatsing: Categorie = {
	label: 'Plaatsing',
	atts: [
		{ att: 'lb_d_plaatsing_va', example: '01-08-2022' },
		{ att: 'lb_d_plaatsing_tm', example: '31-07-2023' },
		{ att: 'lb_nm_bekostigingstype', example: 'Standaard, ExternOnderwijs, NietBekostigbaar' },
		{ att: 'lb_is_bekostigd', example: 'Ja, Nee' },
		{ att: 'lb_is_laatste_plaatsing_sj', example: 'Ja, Nee' },
		{ att: 'lb_is_pl_voorlopig', example: 'Ja, Nee' },
		{ att: 'lb_des_plaatsingstatus', example: 'Definitief, Voorlopig' },
	],
};

const loopbaan_opleiding: Categorie = {
	label: 'Opleiding',
	atts: ['lb_nm_opleiding', 'lb_nr_leerjaar', 'lb_fk_vs.vs_nm_vestiging', 'lb_nm_klas'],
};

export const opleiding_kenmerk: Categorie = {
	label: 'Kenmerk',
	atts: [
		{ att: 'ilt_is_lwoo', example: 'Ja, Nee' },
		{ att: 'ilt_is_leerwerktraject', example: 'Ja, Nee' },
		{ att: 'ilt_is_vavo', example: 'Ja, Nee' },
	],
};

export const opleiding_opleiding: Categorie = {
	label: 'Opleiding',
	atts: [
		{ att: 'ilt_co_ilt', example: '0011 - 7965' },
		{ att: 'ilt_nm_niveau', example: 'VMBO b, PrO, HAVO / VWO, ...' },
		{ att: 'ilt_nm_opleiding', example: 'vmbo-gl/tl/havo voorbereid. lj, havo natuur/techniek, ...' },
		{
			att: 'ilt_des_opleiding',
			example: 'Leerjaar Voorbereidend op VMBO Theoretische/Gemengde Leerweg, HAVO Profiel Natuur en Techniek, ...',
		},
		{ att: 'ilt_nm_leerweg', example: 'BL, KL, GL, TL, HAV, VWO, ...' },
		{ att: 'ilt_abb_profiel', example: 'NT, ZW, EM, ...' },
		{ att: 'ilt_nm_profiel', example: 'Zorg en Welzijn, Economie en maatschappij, ...' },
	],
};

export const loopbaan_instroom_uitstroom: Categorie = {
	label: 'Instroom - Uitstroom',
	atts: [
		{ att: 'lb_nm_instroom', example: 'Extern, Intern, Extern (tussentijds), Geen, ...' },
		{ att: 'lb_nm_instroom_in_schooljaar', label: 'Instroom in schooljaar', example: 'Extern, Intern, Extern (tussentijds), Geen, ...' },
		{ att: 'lb_nm_instroommoment', example: 'T/m 1-10, Na 1-10' },
		{ att: 'lb_nm_instroomtype', example: 'Extern, ' },
		{ att: 'lb_nm_uitstroom', example: 'Extern, Intern, Extern (tussentijds), VAVO, ...' },
		{ att: 'lb_nm_uitstroom_in_schooljaar', label: 'Uitstroom in schooljaar', example: 'Extern, Intern, Extern (tussentijds), VAVO, ...' },
		{ att: 'lb_nm_uitstroommoment', example: 'Einde schooljaar, Tussentijds' },
		{ att: 'lb_nm_uitstroomtype', example: 'Extern, Intern' },
	],
};

export const loopbaan_all = [loopbaan_opleiding, loopbaan_plaatsing];
